import React from "react"
import Layout from "../components/layout"
import style from "../styles/index.module.css"
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"

const productTemplate = data => {
  const {
    data: {
      product: { longText, shortText, price, title, image },
    },
  } = data
  return (
    <Layout>
      <div className={style.topWrapper}>
        <div className={style.productImage}>
          <Img fluid={image.fluid} />
        </div>
        <div>
          <h1>{title}</h1>
          <div
            className={style.shortText}
            dangerouslySetInnerHTML={{
              __html: shortText.childMarkdownRemark.html,
            }}
          ></div>
        </div>
      </div>

      <hr></hr>

      <div
        className={style.shortText}
        dangerouslySetInnerHTML={{ __html: longText.childMarkdownRemark.html }}
      ></div>

      <hr></hr>

      <div className={style.topWrapper}>
        <Link to="/contact/" className={style.cardLink}>
          Kontaktiere mich!
        </Link>
        <h1 className={style.price}>zum Hoferpreis von: {price}€</h1>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query GetSingleProduct($slug: String) {
    product: contentfulProducts(slug: { eq: $slug }) {
      longText {
        childMarkdownRemark {
          html
        }
      }
      shortText {
        childMarkdownRemark {
          html
        }
      }
      price
      title
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
export default productTemplate
